import React from 'react';
import {Layout} from '../components/Layout';


function Oferta() {
	return (
		<Layout
				seo={{
					title: 'Солом’янські котики - публічна оферта',
					description: ``,
				}}
				language={'ua'}
		>
			<section className={'oferta'}>
				<div className="content">
					<h1>ПУБЛІЧНА ОФЕРТА</h1>
					<h2>про надання благодійної допомоги</h2>

					<p>Ця публічна оферта є пропозицією БО «БФ «Солом’янські котики» (фонд) укласти договір про благодійну допомогу шляхом переказу грошових коштів для реалізації статутної діяльності фонду (благодійна допомога). Ця пропозиція поширюється на всіх охочих осіб, що добровільно здійснюють благодійну діяльність (благодійники). Фонд може укладати договори щодо надання благодійної допомоги на інших умовах. Про них благодійник та фонд можуть домовитись окремо.</p>

					<p>Умови оферти вважаються автоматично прийнятими та обов’язковими, якщо ви:</p>
					<ul>
						<li>переказали грошові кошти на рахунок фонду через установи банків</li>
						<li>здійснили грошовий переказ через платіжну систему на сайті фонду.</li>
					</ul>

					<p>Ця оферта набуває чинності з моменту її розміщення на сайті фонду. Вона є безстроковою та може бути зміненою фондом у будь-який час до моменту повного й безумовного її прийняття з боку благодійника (шляхом здійснення грошового переказу через платіжну систему на сайті фонду або переказу грошових коштів на рахунки фонду через установи банків).</p>

					<h2>Діяльність фонду</h2>
					<p>Фонд здійснює благодійну діяльність відповідно до свого Статуту, зокрема у сферах сприяння обороноздатності та мобілізаційній готовності країни, захисту населення у надзвичайних ситуаціях мирного і воєнного стану. Діяльність фонду не має на меті отримання прибутку.</p>

					<p>Залучену благодійну допомогу фонд спрямовує за такими напрямками:</p>
					<ul>
						<li>тактична медицина і домедична допомога</li>
						<li>гуманітарне розмінування</li>
						<li>кризове реагування</li>
					</ul>

					<p>Фонд може самостійно визначати напрями використання благодійної допомоги й переспрямовувати її у межах своєї статутної діяльності. За бажанням благодійник може вказати цільове призначення благодійної допомоги відповідно до проєктів та зборів, які оголошені фондом. Звіти про діяльність фонду розміщуються на сторінках фонду в соціальних мережах та на сайті фонду. Фонд може надати благодійнику звіти про діяльність за його письмовим запитом. Така інформація надається в порядку та у строки, визначені законодавством України.</p>

					<h2>Надання благодійної допомоги</h2>
					<p>Благодійник самостійно визначає розмір благодійної допомоги. Вона є добровільною та не підлягає подальшому поверненню Благодійнику. Благодійник додатково сплачує банківську комісію за переказ благодійної допомоги.</p>

					<p>Способи переказу благодійної допомоги:</p>
					<ul>
						<li><b>Платіжна система LiqPay:</b> зручний сервіс, що дає можливість здійснювати онлайн-перекази за допомогою карток Visa, Mastercard та великої кількості сучасних методів оплати. Благодійник може скористатись сайтом фонду для здійснення переказу через <a href="https://www.liqpay.ua/">https://www.liqpay.ua/</a>.</li>
						<li><b>Платіжна система Monobank:</b> до допомоги фонду можна долучитись, підтримавши збори фонду в Monobank та оформивши заплановані регулярні платежі.</li>
						<li><b>Реквізити фонду:</b> підтримати фонд можна за реквізитами, розміщеними на сайті. Благодійник може надати благодійну допомогу одноразовим внеском через платіжними системи або за реквізитами.</li>
					</ul>

					<p>Благодійник може встановити підписку на регулярні платежі благодійної допомоги. Списання обраної суми відбувається автоматично. Підписка може бути скасована через сервіси банку благодійника.</p>

					<h2>Загальні положення</h2>
					<p>Вся інформація, яка стосується договору, укладеного на підставі цієї оферти, яка не є загальнодоступною та стала відомою сторонам внаслідок укладення або виконання умов цього договору, є конфіденційною. Сторони зобов’язуються не розголошувати та не розкривати конфіденційну інформацію третім особам та використовувати її лише для виконання умов договору.</p>

					<p>Відповідальність фонду за порушення цього договору або порядку використання благодійних пожертв наступає на підставах, в розмірі та в порядку, визначених вимогами чинного законодавства України. У разі виникнення спорів фонд буде докладати всіх зусиль, аби вирішити їх мирно та шляхом переговорів. Якщо це не вдасться зробити, спори розглядатимуться судом у порядку, встановленому чинним законодавством України.</p>

					<h2>Контакти</h2>
					<p>Якщо ви хочете запитати щось у нас, зв’яжіться з нами за електронною адресою: <a href="mailto:kitty.soloma@gmail.com">kitty.soloma@gmail.com</a></p>

					<p>Благодійна організація<br />
					Благодійний фонд “Солом’янські котики”<br />
					ЄДРПОУ: 44791702</p>
				</div>
			</section>
		</Layout>
	);
}

export default Oferta;
